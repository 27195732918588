/* You can add global styles to this file, and also import other style files */
@media only screen and (max-width: 600px) {
  .nb-theme-tumla h5 {
    font-size: 0.8rem;
  }

  .nb-theme-tumla nb-tabset .tab-link {
    font-size: 0.75rem;
    line-height: 0.5rem;
  }

  .nb-theme-tumla nb-select.size-medium .select-button {
    font-size: 0.75rem;
  }

  .nb-theme-tumla nb-option-list.size-medium nb-option {
    font-size: 0.75rem;
  }


}
