@mixin ngx-layout() {
  @include media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  nb-layout-header nav {
    min-height: 4.75rem;
    height: auto;
  }
}
