// @nebular theming framework
@import '~@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '~@nebular/theme/styles/themes';

$nb-themes: nb-register-theme(

  (
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: #f7f9fc,
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3
  ),
  default,
  default
);

$nb-themes: nb-register-theme(

  (
    color-primary-100: #feebcb,
    color-primary-200: #fed298,
    color-primary-300: #fcb264,
    color-primary-400: #f9923e,
    color-primary-500: #f66000,
    color-primary-600: #d34500,
    color-primary-700: #b13000,
    color-primary-800: #8e1e00,
    color-primary-900: #761100,
    color-primary-transparent-100: rgba(246, 96, 0, 0.08),
    color-primary-transparent-200: rgba(246, 96, 0, 0.16),
    color-primary-transparent-300: rgba(246, 96, 0, 0.24),
    color-primary-transparent-400: rgba(246, 96, 0, 0.32),
    color-primary-transparent-500: rgba(246, 96, 0, 0.4),
    color-primary-transparent-600: rgba(246, 96, 0, 0.48),
    color-success-100: #edfddc,
    color-success-200: #d7fbba,
    color-success-300: #b9f595,
    color-success-400: #9cec78,
    color-success-500: #70e04e,
    color-success-600: #4ec039,
    color-success-700: #32a127,
    color-success-800: #1a8118,
    color-success-900: #0e6b14,
    color-success-transparent-100: rgba(112, 224, 78, 0.08),
    color-success-transparent-200: rgba(112, 224, 78, 0.16),
    color-success-transparent-300: rgba(112, 224, 78, 0.24),
    color-success-transparent-400: rgba(112, 224, 78, 0.32),
    color-success-transparent-500: rgba(112, 224, 78, 0.4),
    color-success-transparent-600: rgba(112, 224, 78, 0.48),
    color-info-100: #d0f5fe,
    color-info-200: #a1e5fe,
    color-info-300: #72d1fe,
    color-info-400: #4fbbfd,
    color-info-500: #1698fc,
    color-info-600: #1076d8,
    color-info-700: #0b58b5,
    color-info-800: #073e92,
    color-info-900: #042c78,
    color-info-transparent-100: rgba(22, 152, 252, 0.08),
    color-info-transparent-200: rgba(22, 152, 252, 0.16),
    color-info-transparent-300: rgba(22, 152, 252, 0.24),
    color-info-transparent-400: rgba(22, 152, 252, 0.32),
    color-info-transparent-500: rgba(22, 152, 252, 0.4),
    color-info-transparent-600: rgba(22, 152, 252, 0.48),
    color-warning-100: #fef7cf,
    color-warning-200: #feeea0,
    color-warning-300: #fde170,
    color-warning-400: #fbd44d,
    color-warning-500: #f9c013,
    color-warning-600: #d69f0d,
    color-warning-700: #b38009,
    color-warning-800: #906306,
    color-warning-900: #774e03,
    color-warning-transparent-100: rgba(249, 192, 19, 0.08),
    color-warning-transparent-200: rgba(249, 192, 19, 0.16),
    color-warning-transparent-300: rgba(249, 192, 19, 0.24),
    color-warning-transparent-400: rgba(249, 192, 19, 0.32),
    color-warning-transparent-500: rgba(249, 192, 19, 0.4),
    color-warning-transparent-600: rgba(249, 192, 19, 0.48),
    color-danger-100: #fee9df,
    color-danger-200: #fecec0,
    color-danger-300: #feaea0,
    color-danger-400: #fd8f89,
    color-danger-500: #fc6267,
    color-danger-600: #d84759,
    color-danger-700: #b5314c,
    color-danger-800: #921f41,
    color-danger-900: #78123a,
    color-danger-transparent-100: rgba(252, 98, 103, 0.08),
    color-danger-transparent-200: rgba(252, 98, 103, 0.16),
    color-danger-transparent-300: rgba(252, 98, 103, 0.24),
    color-danger-transparent-400: rgba(252, 98, 103, 0.32),
    color-danger-transparent-500: rgba(252, 98, 103, 0.4),
    color-danger-transparent-600: rgba(252, 98, 103, 0.48)
  ),
  tumla,
  default
); // <- theme name and a parent theme

// ------ Custom ------

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-one {
  flex: 1;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.padding-all-8 {
  padding: 8px;
}

.padding-vertical-8 {
  padding: 8px 0;
}

.padding-horizontal-8 {
  padding: 0 8px;
}

.padding-left-8 {
  padding-left: 8px;
}

.padding-top-8 {
  padding-top: 8px;
}

.margin-all-8 {
  margin: 8px;
}

.margin-vertical-8 {
  margin: 8px 0;
}

.margin-horizontal-8 {
  margin: 0 8px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-top-8 {
  margin-top: 8px;
}

.cursor-pointer {
  cursor: pointer;
}
